import { action, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import Testimonial from '../models/Testimonials'

export class TestimonialsStore {
  @observable consultantInfo = {}
  @observable approvalConsultantInfo = {}
  @observable testimonialsInfo = {}
  @observable fetchDate = false
  @observable fetchApprovalData = false
  @observable testimonialOpenModal = false
  @observable redirect = false
  @observable errors = ''
  @observable loader = false
  @observable comment = ''
  @observable testimonialsList = {}
  @observable consultantResponse = {}
  @observable selectRecruiter = false
  @observable toprecRatings = {}
  @observable googleRatings = {}
  @observable allRatings = {}

  @action
  fetchTestimonials = async (data) => {
    try {
      const res = await API.postData(ApiRoutes.testimonials.check, data)
      this.setData('consultantInfo', res.data)
    } catch (error) {
      Logger.error(error)
      /**
       * @todo make this condition clear [refactor]
       * @note redirect when link has expired
       */

      if (
        error.status === 400 &&
        error.data?.codes?.non_field_errors[0] === 'expired'
      ) {
        this.setData('redirect', true)
        this.setData('errors', 'expired')
      }
      if (error.status === 404) {
        this.setData('redirect', true)
        this.setData('errors', 'expired')
      }
      /**
       * @note redirect when link has not expired
       */
      if (error.status === 400) {
        this.setData('redirect', true)
      }
    } finally {
      this.setData('fetchDate', true)
    }
  }

  @action
  fetchTestimonialRevision = async (testimonialID) => {
    try {
      const response = await API.getData(
        ApiRoutes.testimonials.revision(testimonialID)
      )
      console.log(response)
      this.setData('consultantInfo', response.data.testimonial)
      const needSelectRecruiter = response.data.need_select_recruiter
      this.setData('selectRecruiter', needSelectRecruiter)
      if (typeof needSelectRecruiter === 'boolean' && !needSelectRecruiter) {
        this.setData('consultantResponse', response.data.testimonial)
      }
    } catch (error) {
      Logger.error(error)
      if (
        error.status === 400 &&
        error.data?.codes?.non_field_errors[0] === 'expired'
      ) {
        this.setData('redirect', true)
        this.setData('errors', 'expired')
      }
      if (error.status === 404) {
        this.setData('redirect', true)
        this.setData('errors', 'expired')
      }
      /**
       * @note redirect when link has not expired
       */
      if (error.status === 400) {
        this.setData('redirect', true)
      }
    } finally {
      console.log('fetchTestimonialRevision finally')
      this.setData('fetchDate', true)
    }
  }

  @action
  fetchTestimonialsResponses = async (data) => {
    try {
      const res = await API.getData(ApiRoutes.testimonials.responses(data.id))
      this.setData('testimonialsInfo', res.data)
    } catch (error) {
      Logger.error(error)
      if (error.status === 400) {
        this.setData('redirect', true)
      }
    } finally {
      this.setData('fetchDate', true)
    }
  }

  @action
  checkTestimonial = async (key) => {
    try {
      const res = await API.getData(
        ApiRoutes.testimonials.checkTestimonial(key)
      )
      if (res.status === 404) {
        this.setData('errors', 'expired')
        this.setData('redirect', true)
        return
      }
      this.setData('consultantInfo', res.data)
      const data = {
        rating: res.data.rating,
        comment: res.data.comment,
        testimonial_type: res.data.testimonial_type
      }
      this.setData('consultantResponse', data)
    } catch (error) {
      Logger.error(error)
      if (error.status === 400) {
        this.setData('redirect', true)
      }
    } finally {
      this.setData('fetchDate', true)
    }
  }

  @action
  fetchInviteTestimonials = async (data) => {
    try {
      const res = await API.getData(
        ApiRoutes.testimonials.inviteTestimonial(data.uid)
      )
      this.setData('consultantInfo', res.data)
    } catch (error) {
      Logger.error(error)
      if (error.status === 400) {
        this.setData('redirect', true)
      }
    } finally {
      this.setData('fetchDate', true)
    }
  }

  @action
  checkInviteTestimonial = async (data, cb) => {
    try {
      this.setData('loader', true)
      await API.postData(
        ApiRoutes.testimonials.checkInviteTestimonial(data.uid),
        data
      )
      this.setData('loader', false)
      cb && cb()
    } catch (error) {
      Logger.error(error)
      this.setData('loader', false)
      if (error.status === 400) {
        this.setData('redirect', true)
      }
    }
  }

  @action
  setTestimonials = async (data, cb) => {
    try {
      const res = await API.postData(ApiRoutes.testimonials.create, data)
      if (res && res.data) {
        this.setData('testimonialsInfo', res.data)
        cb && cb()
      }
    } catch (error) {
      Logger.error(error)
      if (error.status === 429 && error.data) {
        toast.error(error.data.detail)
      }
    }
  }

  @action
  setInviteTestimonials = async (data, cb) => {
    try {
      this.setData('loader', true)
      await API.postData(
        ApiRoutes.testimonials.inviteTestimonial(data.uid),
        data
      )
      this.setData('loader', false)
      cb && cb()
    } catch (error) {
      this.setData('loader', false)
      Logger.error(error)
    }
  }

  @action
  updateTestimonials = async (data, cb) => {
    try {
      const res = await API.putData(
        ApiRoutes.testimonials.update(data.id),
        data
      )
      this.setData('testimonialsInfo', res.data)
      cb && cb()
    } catch (error) {
      Logger.error(error)
      if (error.data && error.data.comment) {
        toast.error(error.data.comment)
      }
    }
  }

  @action
  submitReviewedTestimonials = async (data, cb) => {
    try {
      const res = await API.postData(
        ApiRoutes.testimonials.createTestimonialRevision(data.id),
        data
      )
      this.setData('testimonialsInfo', res.data)
      cb && cb()
    } catch (error) {
      Logger.error(error)
      if (error.data && error.data.detail) {
        toast.error(error.data.detail)
      }
    }
  }

  @action
  approveTestimonials = async (data) => {
    try {
      const res = await API.postData(ApiRoutes.testimonials.approve, data)
      this.setData('approvalConsultantInfo', res.data)
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('fetchApprovalData', true)
    }
  }

  @action
  addTestimonials = async (data, isCompanyTestimonial, cb) => {
    try {
      await API.postData(
        isCompanyTestimonial
          ? ApiRoutes.dashboard.agency.companyTestimonials
          : ApiRoutes.dashboard.agency.testimonials,
        data
      )
      this.receiveTestimonials(isCompanyTestimonial)
      cb && cb()
    } catch (e) {
      Logger.error(e)
      if (e.data && e.data.text) {
        toast.error(e.data.text)
      }
    }
  }

  editTestimonials = async (data, id, isCompanyTestimonial, cb) => {
    try {
      await API.putData(
        isCompanyTestimonial
          ? ApiRoutes.dashboard.agency.companyTestimonialsEdit(id)
          : ApiRoutes.dashboard.agency.testimonialsEdit(id),
        data
      )
      this.receiveTestimonials(isCompanyTestimonial)
      cb && cb()
    } catch (e) {
      Logger.error(e)
      if (e.data && e.data.text) {
        toast.error(e.data.text)
      }
    }
  }

  removeTestimonials = async (id, isCompanyTestimonial, cb) => {
    try {
      await API.deleteData(
        isCompanyTestimonial
          ? ApiRoutes.dashboard.agency.companyTestimonialsEdit(id)
          : ApiRoutes.dashboard.agency.testimonialsEdit(id)
      )
      this.receiveTestimonials(isCompanyTestimonial)
      cb && cb()
    } catch (e) {
      Logger.error(e)
    }
  }

  @action
  receiveTestimonials = async (isCompanyTestimonial) => {
    try {
      const res = await API.getData(
        isCompanyTestimonial
          ? ApiRoutes.dashboard.agency.companyTestimonials
          : ApiRoutes.dashboard.agency.testimonials,
        { page_size: 200 }
      )
      const resData = res.data
      resData.results = res.data.results.map((el) => new Testimonial(el))
      this.setData('testimonialsList', resData)
    } catch (e) {
      Logger.error(e)
    }
  }

  @action
  fetchToprecRatings = async (
    { consultantId, consultantTag, authorType } = {
      consultantId: undefined,
      consultantTag: undefined
    }
  ) => {
    const response = await API.getData(
      ApiRoutes.dashboard.toprec.testimonials.list,
      {
        consultant: consultantId,
        tag: consultantTag,
        ...(authorType && { author_type: authorType })
      }
    )
    runInAction(() => {
      this.toprecRatings.averageRating = response.data.average || 0
      this.toprecRatings.totalRatingCount = response.data.count || 0

      this.toprecRatings.count1 = response.data.count_1 || 0
      this.toprecRatings.count2 = response.data.count_2 || 0
      this.toprecRatings.count3 = response.data.count_3 || 0
      this.toprecRatings.count4 = response.data.count_4 || 0
      this.toprecRatings.count5 = response.data.count_5 || 0
      this.toprecRatings.trueScore = response.data.true_score || 0

      this.toprecRatings.count1StarPercentage =
        response.data.count && response.data.count_1
          ? Math.round((response.data.count_1 / response.data.count) * 100)
          : 0
      this.toprecRatings.count2StarPercentage =
        response.data.count && response.data.count_2
          ? Math.round((response.data.count_2 / response.data.count) * 100)
          : 0
      this.toprecRatings.count3StarPercentage =
        response.data.count && response.data.count_3
          ? Math.round((response.data.count_3 / response.data.count) * 100)
          : 0
      this.toprecRatings.count4StarPercentage =
        response.data.count && response.data.count_4
          ? Math.round((response.data.count_4 / response.data.count) * 100)
          : 0
      this.toprecRatings.count5StarPercentage =
        response.data.count && response.data.count_5
          ? Math.round((response.data.count_5 / response.data.count) * 100)
          : 0
    })
  }

  @action
  fetchAllRatings = async ({ authorType } = { authorType: undefined }) => {
    const params = authorType ? { author_type: authorType } : undefined
    const response = await API.getData(
      ApiRoutes.dashboard.toprec.allRatings,
      params
    )
    runInAction(() => {
      this.allRatings.averageRating = response.data.average || 0
      this.allRatings.totalRatingCount = response.data.count || 0

      this.allRatings.count1 = response.data.count_1 || 0
      this.allRatings.count2 = response.data.count_2 || 0
      this.allRatings.count3 = response.data.count_3 || 0
      this.allRatings.count4 = response.data.count_4 || 0
      this.allRatings.count5 = response.data.count_5 || 0
      this.allRatings.trueScore = response.data.true_score || 0

      this.allRatings.count1StarPercentage =
        response.data.count && response.data.count_1
          ? Math.round((response.data.count_1 / response.data.count) * 100)
          : 0
      this.allRatings.count2StarPercentage =
        response.data.count && response.data.count_2
          ? Math.round((response.data.count_2 / response.data.count) * 100)
          : 0
      this.allRatings.count3StarPercentage =
        response.data.count && response.data.count_3
          ? Math.round((response.data.count_3 / response.data.count) * 100)
          : 0
      this.allRatings.count4StarPercentage =
        response.data.count && response.data.count_4
          ? Math.round((response.data.count_4 / response.data.count) * 100)
          : 0
      this.allRatings.count5StarPercentage =
        response.data.count && response.data.count_5
          ? Math.round((response.data.count_5 / response.data.count) * 100)
          : 0
    })
  }

  @action
  fetchGoogleRatings = async ({ authorType } = { authorType: undefined }) => {
    const params = authorType ? { author_type: authorType } : undefined
    const response = await API.getData(
      ApiRoutes.dashboard.toprec.googleRatings,
      params
    )
    runInAction(() => {
      this.googleRatings.averageRating = response.data.average || 0
      this.googleRatings.totalRatingCount = response.data.count || 0

      this.googleRatings.count1 = response.data.count_1 || 0
      this.googleRatings.count2 = response.data.count_2 || 0
      this.googleRatings.count3 = response.data.count_3 || 0
      this.googleRatings.count4 = response.data.count_4 || 0
      this.googleRatings.count5 = response.data.count_5 || 0
      this.googleRatings.trueScore = response.data.true_score || 0

      this.googleRatings.count1StarPercentage =
        response.data.count && response.data.count_1
          ? Math.round((response.data.count_1 / response.data.count) * 100)
          : 0
      this.googleRatings.count2StarPercentage =
        response.data.count && response.data.count_2
          ? Math.round((response.data.count_2 / response.data.count) * 100)
          : 0
      this.googleRatings.count3StarPercentage =
        response.data.count && response.data.count_3
          ? Math.round((response.data.count_3 / response.data.count) * 100)
          : 0
      this.googleRatings.count4StarPercentage =
        response.data.count && response.data.count_4
          ? Math.round((response.data.count_4 / response.data.count) * 100)
          : 0
      this.googleRatings.count5StarPercentage =
        response.data.count && response.data.count_5
          ? Math.round((response.data.count_5 / response.data.count) * 100)
          : 0
    })
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new TestimonialsStore()
